import { UserPointHistoryReasonType } from '../../../types/user';

type AwardReasonToText = {
  [key in UserPointHistoryReasonType]: string;
};

export const awardReasonToText: AwardReasonToText = {
  'class-attended': 'You attended a class today',
  'filled-survey': 'You completed a survey',
  'pre-class-completed': 'You completed your pre-class activity',
  'claimed-free-class': 'You successfully redeemed your Immigot',
  'referred-user': 'You successfully referred your friend',
  'referred-by-user': 'You were referred by your friend',
};
