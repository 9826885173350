import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/convert_kit'
    : 'http://localhost:8080/convert_kit'; // Dev env

const welcomeSequenceID = 1662000;
const welcomeSequenceSpanishID = 2178424;

export async function addEmailToWelcomeEmailSubList(
  email: string,
  first_name: string,
  spanish?: boolean,
) {
  const data = await axios.post(endPoint + `/add_to_sequence`, {
    email,
    first_name,
    sequenceID: spanish ? welcomeSequenceSpanishID : welcomeSequenceID,
  });
  return data?.data;
}
