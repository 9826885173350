import {
  arrayUnion,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { UserData } from '../../types/user';
import { initializeFirebase } from '../configValues';
import { createCustomer } from '../../api/payment';
import { returnUserByUID } from '../configuration';
import {
  EnglishLevelRating,
  EnglishLevelRatingGiven,
} from '../../types/postClassSurvey/rating';
import { dayInMili } from '../../usefuldata/mili';
const { firestore: db } = initializeFirebase();

export const findUserByEmail = async (
  email: string,
): Promise<Array<UserData>> => {
  // For `user's-document-id`, I guess you have it.
  const patientsRef = collection(db, 'users');
  // Create a query against the collection.
  const q = query(patientsRef, where('email', '==', email));
  const res = await getDocs(q);
  const data: Array<UserData> = [];
  res.forEach((doc) => {
    const d = doc.data();
    d.id = doc.id;
    data.push(d as UserData);
  });
  console.log(data);
  return data;
};

export const updateUserData = async (
  userID: string,
  updateMap: Partial<UserData>,
) => {
  let res;
  let err;

  try {
    res = await updateDoc(doc(db, 'users', userID), updateMap);
  } catch (err) {
    err = err;
    console.log(err);
  }
  return { res, err };
};

export const addEnglishRatingToUser = async (
  userID: string,
  newRating: EnglishLevelRating,
) => {
  let res;
  let err;

  try {
    res = await updateDoc(doc(db, 'users', userID), {
      englishLevelRatings: arrayUnion(newRating),
    });
  } catch (err) {
    err = err;
    console.log(err);
  }
  return { res, err };
};

export const addEnglishRatingGivenToUser = async (
  userID: string,
  newRating: EnglishLevelRatingGiven,
) => {
  let res;
  let err;

  try {
    res = await updateDoc(doc(db, 'users', userID), {
      englishLevelRatingsGiven: arrayUnion(newRating),
    });
  } catch (err) {
    err = err;
    console.log(err);
  }
  return { res, err };
};

export const getRecentlyCreatedUsers = async () => {
  let res: any;
  let err;

  try {
    const lowest = new Date().valueOf() - dayInMili * 7;
    const usersRef = await collection(db, 'users');
    const usersQuery = query(usersRef, where('createdAt', '>=', lowest));
    const finalUserList: Array<UserData> = [];
    const users = await getDocs(usersQuery);
    users.forEach((u: any) => {
      const data = u.data();
      data.id = u.id;
      finalUserList.push(data);
    });
    res = finalUserList;
  } catch (err) {
    err = err;
    console.log(err);
  }
  return { res, err };
};

export const assignUserStripeCustomerProfile = async (uid: string) => {
  let error;
  let customerID;
  try {
    const userData = await returnUserByUID(uid);

    if (userData && !userData.customerID) {
      const stripeCustomer = await createCustomer(
        userData.email,
        userData.name,
        uid,
      );
      await updateUserData(uid, {
        customerID: stripeCustomer.id,
      });
      customerID = stripeCustomer.id;
    } else {
      customerID = userData?.customerID;
    }
  } catch (err) {
    error = err;
  }

  return { customerID, error };
};
