import { useEffect, useState } from 'react';
import { YearlyInsightIntro } from './pages/Intro';
import { generateYearlyInsight } from '../../api/insight';
import { YearlyInsightClassInsight } from './pages/classInsight';
import { YearlyInsightWords } from './pages/words';
import { YearlyInsightFriends } from './pages/friends';
import { YearlyTopThreeWords } from './pages/topThree';
import { trackYearlyInsight } from '../../features/YearlyInsight/analytics';
import { useSelector } from 'react-redux';
import { UserData } from '../../types/user';
import { LoadingOverlay } from '../../components/loading';
import { YearlyInsightSummary } from './pages/summary';
import { useHistory } from 'react-router-dom';

export const YearlyInsight = () => {
  const [page, setPage] = useState(0);
  const [insightDoc, setInsightDoc] = useState<any>();
  const [loading, setLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const loc = urlParams.get('page');
  const history = useHistory();

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  const uid = userData?.id;
  const year = 2023;
  const hasTranscriptInsight = insightDoc?.wordCount > 100; // Has to have more than 200 words to have something meaningful

  async function updateInsight() {
    setLoading(true);
    console.log('generating insight');
    const insight = await generateYearlyInsight(2024, uid);
    setInsightDoc(insight);
    setLoading(false);
    console.log(insight);
  }

  function locationToPage() {
    switch (loc) {
      case 'welcome':
        setPage(0);
        break;
      case 'class':
        setPage(1);
        break;
      case 'words':
        setPage(2);
        break;
      case 'top-three':
        setPage(3);
        break;
      case 'friends':
        setPage(4);
        break;
      case 'summary':
        setPage(5);
        break;
      default:
        setPage(0);
    }
  }

  function renderPage() {
    const pages = [
      <YearlyInsightIntro
        onNext={() => {
          trackYearlyInsight('Wrapped clicked next', {
            page: 'welcome',
            uid: uid,
            year: year,
          });
          setPage(1);
          history.push('/wrapped?page=class', { shallow: true });
        }}
      />,
      <YearlyInsightClassInsight
        numberOfClasses={insightDoc?.numberOfClasses}
        classTime={insightDoc?.minutesSpent}
        onNext={() => {
          trackYearlyInsight('Wrapped clicked next', {
            page: 'class',
            uid: uid,
            year: year,
          });
          setPage(2);
          history.push('/wrapped?page=words', { shallow: true });
        }}
        onBack={() => {
          trackYearlyInsight('Wrapped clicked back', {
            page: 'class',
            uid: uid,
            year: year,
          });
          setPage(0);
          history.push('/wrapped?page=welcome', { shallow: true });
        }}
      />,
      <YearlyInsightWords
        numberOfWords={insightDoc?.wordCount}
        wordMap={insightDoc?.masterWordMap}
        idioms={insightDoc?.phrasesUsed}
        phrasesLearnedCount={insightDoc?.learnedPhrasesCount}
        onNext={() => {
          trackYearlyInsight('Wrapped clicked next', {
            page: 'words',
            uid: uid,
            year: year,
          });
          if (hasTranscriptInsight) {
            setPage(3);
            history.push('/wrapped?page=top-three', { shallow: true });
          } else {
            setPage(4);
            history.push('/wrapped?page=friends', { shallow: true });
          }
        }}
        onBack={() => {
          trackYearlyInsight('Wrapped clicked back', {
            page: 'words',
            uid: uid,
            year: year,
          });
          setPage(1);
          history.push('/wrapped?page=class', { shallow: true });
        }}
      />,
      <YearlyTopThreeWords
        wordMap={insightDoc?.masterWordMap}
        onNext={() => {
          trackYearlyInsight('Wrapped clicked next', {
            page: 'top-three',
            uid: uid,
            year: year,
          });
          setPage(4);
          history.push('/wrapped?page=friends', { shallow: true });
        }}
        onBack={() => {
          trackYearlyInsight('Wrapped clicked back', {
            page: 'top-three',
            uid: uid,
            year: year,
          });
          setPage(2);
          history.push('/wrapped?page=words', { shallow: true });
        }}
      />,
      <YearlyInsightFriends
        listOfCountries={insightDoc?.metNationalityList}
        metList={insightDoc?.metUserList}
        metUsersCount={insightDoc?.metUsersCount}
        onNext={() => {
          trackYearlyInsight('Wrapped clicked next', {
            page: 'friends',
            uid: uid,
            year: year,
          });
          setPage(5);
          history.push('/wrapped?page=summary', { shallow: true });
        }}
        onBack={() => {
          trackYearlyInsight('Wrapped clicked back', {
            page: 'friends',
            uid: uid,
            year: year,
          });
          if (hasTranscriptInsight) {
            setPage(3);
            history.push('/wrapped?page=top-three', { shallow: true });
          } else {
            setPage(2);
            history.push('/wrapped?page=words', { shallow: true });
          }
        }}
      />,
      <YearlyInsightSummary insightDoc={insightDoc} />,
    ];

    return pages[page];
  }

  useEffect(() => {
    locationToPage();
    trackYearlyInsight('Visited wrapped class insight page', {
      uid: uid,
      year: year,
    });
  }, []);

  useEffect(() => {
    if (userData) updateInsight();
    else setLoading(true);
  }, [userData?.id]);

  return (
    <div className="w-screen h-screen bg-gradient-to-r from-green-400 to-blue-500">
      <LoadingOverlay
        enabled={loading}
        loadingMessage="Generating your yearly insight!"
      />
      {insightDoc ? renderPage() : null}
    </div>
  );
};
