import { useEffect } from 'react';
import { trackYearlyInsight } from '../../YearlyInsight/analytics';
import { SparkleButton } from '../../../components/buttons/sparkle/sparkleButton';

export const WrappedHomeContainer = (props: { year: number }) => {
  const { year } = props;

  useEffect(() => {
    trackYearlyInsight('Shown wrapped container at homepage', {
      year: year,
    });
  }, []);

  return (
    <div
      className="@container rounded-lg shadow w-full py-6 px-4 flex relative mb-2 cursor-pointer
            bg-gradient-to-r from-fuchsia-500 via-red-600 to-orange-400 "
      onClick={() => {
        trackYearlyInsight('Clicked wrapped container at homepage', {
          year: year,
        });
        window.open('/wrapped/', '_blank');
      }}
    >
      <div className="text-white w-full">
        <div className="text-3xl mb-2">{`Your ${year} #ImmigoWrapped`}</div>
        <div className="flex items-center">
          <div className="max-w-[300px]">
            <SparkleButton text="Click here" onClick={() => {}} />
          </div>
          <div className="ml-2">
            to unwrap your English learning journey highlights!
          </div>
        </div>
      </div>
    </div>
  );
};
