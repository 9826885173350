import { useEffect } from 'react';
import {
  ImprovementPeerRating,
  ImprovementPeerRatingEventObject,
  ImprovementRatingType,
} from '../../../types/postClassSurvey/rating';
import VerticalMessageModal from '../verticalMessageModal/verticalMessageModal';
import { ActivityEventEntry } from '../../feed/components/eventEntry';
import { getFirstNameFromFullName } from '../../../util/helper';
import { capitalizeFirstLetter } from '../../../util/standardization';

export const ImprovementReportModal = (props: {
  onCloseModal: Function;
  isOpen: boolean;
  ratings: Array<ImprovementPeerRatingEventObject>;
}) => {
  const { isOpen, onCloseModal, ratings } = props;
  const iconPictureURL = undefined;

  return (
    <VerticalMessageModal
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      mainMessage="Look at you go!"
      subMessage="Check out all the things your Immigo friends have said!"
      buttonText="Continue"
      onButtonClick={() => {
        onCloseModal();
      }}
      theme="success"
      icon="star"
    >
      <div className="mt-4 max-h-[400px] overflow-y-scroll">
        {ratings.map((rating) => {
          return (
            <div className="py-2 px-1">
              <ActivityEventEntry
                pictureUrl={rating.reportedByProfilePictureUrl}
                date={rating.createdAt}
                content={`${capitalizeFirstLetter(
                  getFirstNameFromFullName(rating.reportedByName ?? 'someone'),
                )} said that your 
                ${rating.improvedTags
                  .map((tag: ImprovementRatingType, index: number) => {
                    let color;
                    switch (tag) {
                      case 'confidence':
                        color = 'text-blue-500';
                        break;
                      case 'pronunciation':
                        color = 'text-orange-500';
                        break;
                      case 'grammar':
                        color = 'text-red-500';
                        break;
                      case 'word-range':
                        color = 'text-yellow-500';
                        break;
                      default:
                        color = 'text-black';
                    }
                    return `<span class="${color} font-semibold">${
                      tag === 'word-range' ? 'word range' : tag
                    }</span>${
                      index < rating.improvedTags.length - 1 ? ', ' : ''
                    }${index === rating.improvedTags.length - 2 ? 'and ' : ''}`;
                  })
                  .join('')}
                 improved!`}
              />
            </div>
          );
        })}
      </div>
    </VerticalMessageModal>
  );
};
