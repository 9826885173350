import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { initializeFirebase } from '../../../firebase/configValues';
import { ImmigotRewardModal } from '../../../components/modals/immigotRewardModal/immigotRewardModal';
import { useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import {
  UserPointHistory,
  UserPointHistoryReasonType,
} from '../../../types/user';
import { useDispatch } from 'react-redux';
import { trackImmigotsNotifierEvents } from './analytics';

const { firestore } = initializeFirebase();

const usePointsHistory = (userID: string) => {
  const [data, loading, error] = useDocumentData(
    doc(firestore, `users/${userID}`),
  );

  if (error) throw error;
  return {
    pointsHistory: data?.pointsHistory,
    pointsHistoryLoading: loading,
    pointsHistoryError: error,
  };
};

const usePoints = (userID: string) => {
  const [data, loading, error] = useDocumentData(
    doc(firestore, `users/${userID}`),
  );

  if (error) throw error;
  return {
    points: data?.points,
    pointsLoading: loading,
    pointsError: error,
  };
};

export const ImmigotsNotifier = (props: { userID: string }) => {
  const { userID } = props;
  const { pointsHistory } = usePointsHistory(userID);
  const { points } = usePoints(userID);
  const [openRewardModal, setOpenRewardModal] = useState(false);
  const [previousPointsHistory, setPreviousPointsHistory] = useState<
    undefined | Array<UserPointHistory>
  >();
  const [awardedAmount, setAwardedAmount] = useState<undefined | number>();
  const [reason, setReason] = useState<
    undefined | UserPointHistoryReasonType
  >();
  const { width, height } = useWindowSize();
  const dispatch = useDispatch();
  const updatePointsOnUser = (points: any) =>
    dispatch({ type: 'UPDATE_USER_POINTS', points: points });

  useEffect(() => {
    if (
      previousPointsHistory &&
      previousPointsHistory.length < (pointsHistory?.length ?? 0) &&
      pointsHistory[pointsHistory.length - 1].amount > 0
    ) {
      setOpenRewardModal(true);
      trackImmigotsNotifierEvents('Immigots notifier opened award modal', {
        amount: pointsHistory[pointsHistory.length - 1].amount,
        reason: pointsHistory[pointsHistory.length - 1].reason,
      });
      setReason(pointsHistory[pointsHistory.length - 1].reason);
      setAwardedAmount(pointsHistory[pointsHistory.length - 1].amount);
    }
    setPreviousPointsHistory(pointsHistory);
  }, [pointsHistory]);

  useEffect(() => {
    updatePointsOnUser(points);
  }, [points]);

  return (
    <>
      {openRewardModal ? (
        <div className="z-100">
          <ReactConfetti
            width={width}
            height={height}
            numberOfPieces={320}
            recycle={true}
          />
        </div>
      ) : null}
      {awardedAmount && reason ? (
        <ImmigotRewardModal
          isOpen={openRewardModal}
          reason={reason}
          amount={awardedAmount}
          onCloseModal={() => {
            trackImmigotsNotifierEvents(
              'Immigots notifier closed award modal',
              {
                amount: awardedAmount,
                reason: reason,
              },
            );
            setOpenRewardModal(false);
            setReason(undefined);
            setAwardedAmount(undefined);
          }}
        />
      ) : null}
    </>
  );
};
