import ReactConfetti from 'react-confetti';
import { PrimaryButton } from '../../../components/buttons/primary';
import { ScreenShotButton } from '../../../components/buttons/screenShot/screenShotButton';
import { SimpleBackAndNext } from '../../../components/buttons/simpleBackAndNext/simpleBackAndNext';
import { ImmigoWhiteLogo } from '../../../components/icons/immigoWhite';
import { trackYearlyInsight } from '../../../features/YearlyInsight/analytics';
import { useWindowSize } from 'react-use';
import { useEffect } from 'react';
import { topOccurenceMapWithoutCommonWords } from '../../../util/data';
import { capitalizeFirstLetter } from '../../../util/standardization';
import { Fade } from 'react-awesome-reveal';
import { countryCodeToSVG } from '../../../util/flagLoader';
import cx from 'classix';

export const YearlyInsightSummary = (props: { insightDoc: any }) => {
  const { insightDoc } = props;

  const {
    numberOfClasses,
    minutesSpent,
    wordCount,
    phrasesUsed,
    masterWordMap,
    metNationalityList,
    metUserList,
    metUsersCount,
    learnedPhrasesCount,
  } = insightDoc ?? {};

  function getTopThreeWords() {
    return topOccurenceMapWithoutCommonWords(masterWordMap ?? {}, 3);
  }

  const { width, height } = useWindowSize();

  useEffect(() => {
    trackYearlyInsight('Visited wrapped summary page', {
      page: 'summary',
    });
  }, []);

  return (
    <div className="text-center flex flex-col justify-center items-center py-24 bg-gradient-to-r from-pink-400 to-blue-500 h-full">
      <ReactConfetti
        width={width}
        height={height}
        numberOfPieces={160}
        recycle={true}
      />
      <div className="w-48 flex">
        <ImmigoWhiteLogo />
      </div>
      <ScreenShotButton
        onClick={() => {
          trackYearlyInsight('Wrapped share with friends clicked', {
            page: 'summary',
          });
        }}
      />
      <div className="text-white text-3xl mt-12 mb-6 font-bold">
        Your 2024 summary
      </div>
      <div className="text-white text-xl text-center">
        <div className="flex justify-center mb-1">
          <span className="mr-2">Classes taken: </span>
          <span>{numberOfClasses} classes</span>
        </div>
        <div className="flex justify-center mb-1">
          <span className="mr-2">Minutes learning English: </span>
          <span>{minutesSpent} minutes</span>
        </div>
        {wordCount > 100 ? (
          <div className="flex justify-center mb-1">
            <span className="mr-2">English words spoken: </span>
            <span>{wordCount} words</span>
          </div>
        ) : null}
        <div className="flex justify-center mb-1">
          <span className="mr-2">Phrases learned: </span>
          <span>{learnedPhrasesCount ?? 0} phrases</span>
        </div>
        {wordCount > 100 ? (
          <div className="flex flex-col justify-center mb-1">
            <span className="mb-2">Top 3 words: </span>
            {getTopThreeWords().map((w: string, index: number) => {
              return (
                <div className="text-xl" key={w + index}>
                  {`${index + 1}. ${capitalizeFirstLetter(w)} : ${
                    masterWordMap?.[w]
                  }`}
                </div>
              );
            })}
          </div>
        ) : null}

        <div className="flex justify-center mb-1">
          <span className="mr-2">Other students met: </span>
          <span>{metUsersCount ?? 0} students</span>
        </div>
        <div className="flex flex-col justify-center items-center mb-1">
          <span className="mb-2">Nationalities met: </span>
          <div
            className={cx(
              'flex grid',
              metNationalityList > 7 ? 'grid-cols-8' : 'grid-cols-3 w-[200px]',
            )}
          >
            {metNationalityList?.map((country: string) => {
              if (country !== 'UN')
                return (
                  <img
                    key={country}
                    className="md:w-10 w-8 h-auto mr-2 my-1"
                    src={countryCodeToSVG(country)}
                  />
                );
            })}
          </div>
        </div>
        <div className="flex max-w-[300px] flex-wrap text-center justify-center"></div>
      </div>
      <div data-html2canvas-ignore="true"></div>
    </div>
  );
};
