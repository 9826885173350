import mixpanel from '../../../analytics/mixpanel';

type Events =
  | 'Improvement report notifier modal opened'
  | 'Improvement report notifier modal closed';

type ImprovementReportNotifierData = {
  reportNumber?: number;
  lastOpened?: number;
};

export const trackImprovementReportNotifier = (
  event: Events,
  data?: ImprovementReportNotifierData,
) => {
  mixpanel.track(event, data);
};
