import { useEffect, useState } from 'react';
import {
  BookingData,
  getClass,
  getClassSession,
} from '../../firebase/subscription/subscription';
import { getBookersFromClassSession } from '../../firebase/subscription/classSessions/classSessions';
import { ClassInfoTablePreview } from '../../components/tables/classInfoTablePreview';
import UserTable, {
  UserTableObject,
} from '../../components/userTable/userTable';
import cx from 'classix';
import { tagToColorStyle } from '../../util/bookings';
import { capitalizeFirstLetter } from '../../util/standardization';
import { trackClassDetailPage } from '../../features/ClassDetailPage/analytics';
import { useSelector } from 'react-redux';
import { UserData } from '../../types/user';
import { getUserFollowings } from '../../firebase/social/social';
import { followUser, unfollowUser } from '../../api/social';

export const ClassDetailPage = (props: {}) => {
  const [classData, setClassData] = useState<undefined | BookingData>();
  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  const urlParams = new URLSearchParams(window.location.search);
  const sessionID = urlParams.get('cid');

  async function onLoad() {
    if (!sessionID || sessionID.length === 0) return;

    trackClassDetailPage('Class Detail Page: Visited class detail page', {
      sessionID,
    });

    const followings =
      ((await getUserFollowings(userData.id)) ?? []).map((d) => d.id) ?? [];
    const session: BookingData = await getClassSession(sessionID);
    const classData: BookingData = (await getClass(
      session?.classID,
    )) as BookingData;
    const bookers = (await getBookersFromClassSession(
      sessionID,
    )) as UserTableObject[];
    bookers.forEach((b) => {
      b.following = followings.includes(b.id);
    });

    session.tag = classData?.tag;
    session.recLevel = classData?.recLevel;
    session.teacher = classData?.teacher;

    if (session) session.bookerList = bookers;
    setClassData(session);
  }

  async function onFollow(isFollowing: boolean, uid: string) {
    if (!isFollowing) {
      trackClassDetailPage('Class Detail Page: Followed user');
      await followUser(userData.id, uid);
    } else {
      trackClassDetailPage('Class Detail Page: Unfollowed user');
      await unfollowUser(userData.id, uid);
    }
    await onLoad();
  }

  useEffect(() => {
    if (userData) onLoad();
  }, [userData]);

  return (
    <>
      {classData && userData ? (
        <div>
          <div>
            <div className="flex md:flex-row flex-col md:items-left items-center">
              <div className="text-2xl font-semibold">{classData.title}</div>
              <div className="flex items-center md:my-0 my-2">
                {classData.tag ? (
                  <div
                    className={cx(
                      'py-2 px-2 mx-2 font-semibold justify-center',
                      classData.tag ? tagToColorStyle(classData.tag) : '',
                    )}
                  >
                    {capitalizeFirstLetter(classData?.tag)}
                  </div>
                ) : null}
                {classData.recLevel ? (
                  <div
                    className={cx(
                      'py-2 px-2 font-semibold justify-center',
                      'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700',
                    )}
                  >
                    {capitalizeFirstLetter(classData.recLevel)}
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <ClassInfoTablePreview
                slideUrl={classData?.cleanSlide ?? ''}
                startMili={classData.startMili}
                instructorPhoto={classData?.teacher?.profilePictureLink}
                instructorName={classData?.teacher?.name}
                instructorId={classData?.teacher?.id}
              />
            </div>
            <div></div>
          </div>
          <div className="font-semibold mb-2">Participants</div>
          <div className="bg-white py-2 md:px-4 px-8 rounded-md">
            <UserTable
              userList={classData.bookerList ?? []}
              onFollow={onFollow}
              name
              job
              country
              followButton
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
