import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getClientSecret, subscribeUserMembership } from '../../../../api';
import { PrimaryButton } from '../../../buttons/primary';
import { updateUserCustomerID } from '../../../../firebase/configuration';
import cx from 'classix';
import { attachPaymentMethod, createCustomer } from '../../../../api/payment';
import { trackCheckoutEvent } from '../../../../features/Payment/analytics';
import { stringsPricingPage } from '../../../../util/localization';

const STRIPE_KEY =
  process.env.REACT_APP_ENV === 'PROD'
    ? process.env.REACT_APP_LIVE_PUBLISHABLE_KEY
    : process.env.REACT_APP_TEST_PUBLISHABLE_KEY;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_KEY ? STRIPE_KEY : '');

const CheckoutForm = ({
  price,
  userData,
  onSubmit,
  onFailure,
  onSuccess,
  subscription,
  disabled,
  trial,
  trialPeriod,
  currency,
  couponID,
}: any) => {
  const stripe = useStripe();
  const elements = useElements();

  async function handleSavePaymentMethod(e: any) {
    e.preventDefault();
    if (onSubmit) onSubmit();
    if (!userData) return; // Stop the user from checking out unless registered

    const cardElement = elements?.getElement(CardElement);

    const paymentMethod = cardElement
      ? await stripe?.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: userData.name,
            email: userData.email,
          },
        })
      : undefined;

    let customerID = userData?.customerID;

    if (!customerID) {
      const customer = await createCustomer(
        userData?.email,
        userData?.name,
        userData?.id,
      );
      customerID = customer?.id;
      await updateUserCustomerID(userData?.id, customerID);
    }

    let updateStatus;
    if (customerID && paymentMethod?.paymentMethod?.id)
      updateStatus = await attachPaymentMethod(
        paymentMethod?.paymentMethod?.id,
        customerID,
      );

    console.log(updateStatus);
    console.log(paymentMethod);

    // This probably should go into the server and process everything there for security & consistency
    if (
      paymentMethod &&
      customerID &&
      !paymentMethod.error &&
      updateStatus?.status !== 'failure'
    ) {
      trackCheckoutEvent('Saved card information', {
        userID: userData?.id,
        email: userData?.email,
      });
      if (onSuccess) onSuccess();
    } else {
      if (onFailure) onFailure();
    }
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    if (onSubmit) onSubmit();
    if (!userData) return; // Stop the user from checking out unless registered

    const intentResponse = await getClientSecret(
      Math.round(price.toFixed(2) * 100),
      'usd',
      'card',
      userData?.email,
      userData?.name,
      userData?.id,
      userData?.customerID,
    );

    const clientSecret = intentResponse
      ? intentResponse.data.paymentIntent.client_secret
      : undefined;

    const customerID = intentResponse
      ? intentResponse.data.customerID
      : undefined;

    if (customerID && userData?.id)
      await updateUserCustomerID(userData.id, customerID);

    const cardElement = elements?.getElement(CardElement);

    // Confirm the payment on the client
    const { paymentIntent }: any = cardElement
      ? await stripe?.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: userData.name,
              email: userData.email,
            },
          },
          setup_future_usage: 'off_session',
        })
      : undefined;

    // This probably should go into the server and process everything there for security & consistency
    if (paymentIntent && paymentIntent.status === 'succeeded') {
      if (onSuccess) onSuccess();
    } else {
      if (onFailure) onFailure();
    }
  }

  async function handleSubmitSub(e: any) {
    e.preventDefault();
    if (onSubmit) onSubmit();
    if (!userData) return; // Stop the user from checking out unless registered

    const cardElement = elements?.getElement(CardElement);

    const result = cardElement
      ? await stripe?.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: userData.name,
            email: userData.email,
          },
        })
      : undefined;

    console.log(result);
    if (!result || result.error) {
      console.log('Error');
      onFailure(result?.error?.message);
    } else {
      console.log('subbing');
      const res = await subscribeUserMembership(
        result.paymentMethod.id,
        userData.email,
        userData.name,
        price,
        userData.id,
        userData.customerID,
        trial && trialPeriod ? trialPeriod : undefined,
        couponID,
        currency ?? 'usd',
      );
      // eslint-disable-next-line camelcase
      const { client_secret, customer_id, status, error } = res.data;
      if (userData && customer_id && !userData.customerID) {
        await updateUserCustomerID(userData.id, customer_id); // Update customer data
      }
      console.log(status);

      if (status === 'requires_action') {
        stripe?.confirmCardPayment(client_secret).then(function (result) {
          if (result.error) {
            console.log('There was an issue!');
            console.log(result.error);
            if (onFailure) onFailure(result.error?.message);
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
          } else {
            console.log('You got the money!');
            if (onSuccess) onSuccess();
            // Show a success message to your customer
          }
        });
      } else if (status === 'failed') {
        console.log('failed attempt');
        if (onFailure) onFailure(error);
      } else if (status === 'requires_payment_method') {
        if (onFailure)
          onFailure('This card has been declined. Please try another card.');
      } else if (status === 'succeeded' || status === 'trialing') {
        if (onSuccess) onSuccess();
        console.log('You got the money!');
        // No additional information was needed
        // Show a success message to your customer
      } else {
        if (onFailure) onFailure(error);
      }
    }
    // This probably should go into the server and process everything there for security & consistency
    // if(paymentIntent && paymentIntent.status === "succeeded") {
    //   if(onSuccess) onSuccess();
    // }
    // else {
    //   if(onFailure) onFailure();
    // }
  }

  return (
    <form className="w-full" id="payment-form">
      <CardElement />
      <div className={`md:justify-start justify-center md:mx-0 mx-auto`}>
        {trial && !subscription ? (
          <PrimaryButton
            text="Start your free 1-workshop trial"
            className={cx(disabled && 'bg-black-light cursor-not-allowed')}
            onClick={handleSavePaymentMethod}
            disabled={disabled}
          />
        ) : (
          <PrimaryButton
            text={
              trial
                ? stringsPricingPage.start7WeekTrial
                : stringsPricingPage.pay
            }
            className={cx(disabled && 'bg-black-light cursor-not-allowed')}
            onClick={subscription ? handleSubmitSub : handleSubmit}
            disabled={disabled}
          />
        )}
      </div>
    </form>
  );
};

const CardPaymentForm = ({
  price,
  userData,
  onSubmit,
  onFailure,
  onSuccess,
  subscription,
  disabled,
  trial,
  trialPeriod,
  couponID,
  currency,
}: any) => {
  return (
    <div className="flex max-w-md">
      <Elements stripe={stripePromise} options={{}}>
        <CheckoutForm
          subscription={subscription}
          price={price}
          userData={userData}
          onSubmit={onSubmit}
          onFailure={onFailure}
          onSuccess={onSuccess}
          disabled={disabled}
          trial={trial}
          trialPeriod={trialPeriod}
          couponID={couponID}
          currency={currency}
        />
      </Elements>
    </div>
  );
};

export default CardPaymentForm;
