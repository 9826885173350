import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/postClassSurvey'
    : 'http://localhost:8080/postClassSurvey'; // Dev env

export async function createPostClassImprovementReport(
  reportee: string,
  reportedBy: string,
  classSessionId: string,
  improvedTags: Array<string>,
  note?: string,
) {
  const res = await axios.post(`${endPoint}/create/improvement`, {
    reportee,
    reportedBy,
    classSessionId,
    improvedTags,
    note,
  });
  return res;
}

export async function getPostClassImprovementReportSession(
  classSessionId: string,
  uid: string,
) {
  const res = await axios.get(
    `${endPoint}/get/session/improvement/${classSessionId}/${uid}`,
  );
  return res?.data?.reports;
}

export async function getPostClassImprovementReportUser(uid: string) {
  const res = await axios.get(`${endPoint}/get/user/improvement/${uid}`);
  return res?.data?.reports;
}
