import { ClockIcon } from '@heroicons/react/24/outline';
import { SparkleButton } from '../../buttons/sparkle/sparkleButton';
import { useState } from 'react';

export const LearningPathCard = (props: {
  title: string;
  description: string;
  image?: any;
  onChosen: Function;
}) => {
  const { title, description, image, onChosen } = props;

  const [loading, setLoading] = useState(false);

  return (
    <div className="border bg-white p-4 rounded-md flex flex-col items-center">
      <div className="text-2xl font-semibold mb-6">{title}</div>
      <img className="h-64 m-auto my-4" src={image} />
      <div className="text-gray-500 mb-6">{description}</div>
      {/* <div className="flex items-center text-gray-500">
        <ClockIcon className="w-5 h-5 mr-2" /><span>250 Hours</span>
      </div> */}
      <div className="mt-auto self-end w-full">
        <SparkleButton
          text="Choose path"
          loading={loading}
          onClick={async () => {
            setLoading(true);
            await onChosen();
            setLoading(false);
          }}
        />
      </div>
    </div>
  );
};
