import axios from 'axios';
import { UserPreviewActivityType } from '../firebase/subscription/subscription';
import { ClassType } from '../types/class';
import { CEFRLevel } from '../types/level';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/waitlist'
    : 'http://localhost:8080/waitlist'; // Dev env

export async function createWaitlist(
  userID: string,
  cefrLevel: CEFRLevel,
  startMili: number,
  title: string,
  tag: ClassType,
  phoneNumber: string,
  teacherID?: string,
  classID?: string,
) {
  const data = await axios.post(`${endPoint}/addWaitlistData`, {
    userID,
    cefrLevel,
    startMili,
    title,
    tag,
    phoneNumber,
    teacherID,
    classID,
  });
  return data?.data;
}

export async function createLevelWaitlist(
  phoneNumber: string,
  email: string,
  level: CEFRLevel,
) {
  const data = await axios.post(`${endPoint}/create/level`, {
    phoneNumber,
    email,
    level,
  });
  return data?.data;
}
