import { ActivityType } from '../../types/social';
import { capitalizeFirstLetter } from '../standardization';

export function activityTypeToString(
  name: string,
  activity: ActivityType,
  subjectName?: string,
  extraContent?: string,
) {
  let content;

  switch (activity) {
    case 'attended-class':
      content = 'attended a class';
      break;
    case 'reviewed-class':
      content = 'reviewed a class';
      break;
    case 'previewed-class':
      content = 'prepared for a class';
      break;
    case 'warmedup-class':
      content = 'warmed up for a class';
      break;
    case 'received-reaction':
      content = `received a reaction ${
        subjectName ? `from ${capitalizeFirstLetter(subjectName)}` : ''
      } ${extraContent ?? ''}`;
      break;
    default:
  }

  return `${capitalizeFirstLetter(name)} ${content}.`;
}
