import moment from 'moment';
import emptyAvatar from '../../../images/empty-avatar.webp';

export const ActivityEventEntry = (props: {
  pictureUrl?: string;
  date: number;
  content: string;
}) => {
  const { pictureUrl, date, content } = props;

  return (
    <div>
      <div className="relative flex space-x-3">
        <div>
          <img
            className="cursor-pointer object-cover object-center bg-white h-8 w-8 rounded-full flex items-center justify-center ring-1 ring-blue-light"
            src={pictureUrl ?? emptyAvatar}
            onClick={() => {
              // onActivityAvatarClicked?.();
              window.open(`profile/${''}`, '_blank');
            }}
          />
        </div>
        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5 text-left">
          <div>
            <p
              className="text-sm text-gray-500"
              dangerouslySetInnerHTML={{ __html: content }}
            ></p>
          </div>
          <div className="whitespace-nowrap text-right text-sm text-gray-500">
            <time dateTime={'date'}>{`${moment(date).fromNow(true)}`} ago</time>
          </div>
        </div>
      </div>
    </div>
  );
};
