import { cx } from 'classix';
import { useEffect, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { PrimaryButton } from '../../../components/buttons/primary';
import { trackBookingAnalytics } from '../../../features/Booking/analytics';
import { BookingEventObject } from '../../../features/Booking/bookingEventContainer';
import WeekBookingCalendar from '../../../features/Booking/weekBookingCalendar';
import { stringsPricingPage } from '../../../util/localization';
import { RecommendedBookingsContainer } from '../../../features/Booking/recommendedBookingsContainer';
import {
  convertMiliTimeToChosenWeekTime,
  convertMiliTimeToImmediateWeekDate,
} from '../../../util/dates';
import {
  getClassWeekCleanSlide,
  getClassWeekTheme,
  getClassWeekTopic,
} from '../../../firebase/subscription/subscription';
import { getRecClasses } from '../../../api/bookings';
import { ClassType } from '../../../types/class';
import BlueAlert from '../../../components/alerts/blueAlert';
import { SparkleButton } from '../../../components/buttons/sparkle/sparkleButton';

const animationDuration = 300;

export function BookingPage(props: {
  userData: any;
  subOption: number | undefined;
  setPage: Function;
  setChosenSchedule: Function;
  chosenSchedule: Array<BookingEventObject>;
  classes: undefined | Array<BookingEventObject>;
  setWantRecur: Function;
  onBookingSubmit: Function;
  wantRecur: boolean;
  setLoading: Function;
  oneClassCap?: boolean;
  cefr?: string;
}) {
  const {
    userData,
    subOption,
    setPage,
    setChosenSchedule,
    chosenSchedule,
    classes,
    setWantRecur,
    wantRecur,
    setLoading,
    onBookingSubmit,
    oneClassCap,
    cefr,
  } = props;

  const [loaded, setLoaded] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [seeCalendar, setSeeCalendar] = useState(false);
  const [recClasses, setRecClasses] = useState<Array<BookingEventObject>>([]);

  const buttonRef = useRef<any>(null);

  const cefrLevel = window.sessionStorage.getItem('cefrLevel');
  const motive = window.sessionStorage.getItem('motivation');

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (chosenSchedule.length === 1) {
      buttonRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [chosenSchedule]);

  async function updateRecommendedClasses() {
    const allRecClasses =
      cefrLevel && motive ? await getRecClasses(cefrLevel, motive) : classes;

    function getTagVal(tag: ClassType | undefined) {
      switch (tag) {
        case 'grammar':
          return 2;
        case 'vocab':
          return 1;
        case 'idiom':
          return 0;
        case 'pronunciation':
          return 3;
        default:
          return 4;
      }
    }

    function mapMotiveToTheme(motive: string) {
      switch (motive) {
        case 'Advance my career':
          return 'career';
        case 'Confidence in social settings':
          return 'social';
        case 'Expand my knowledge':
          return 'any';
        case 'Other':
          return 'any';
        default:
          return 'any';
      }
    }

    console.log(allRecClasses.filter((c: BookingEventObject) => !c.id));

    let totalRecClasses: Array<BookingEventObject> = [];
    const tags: Array<ClassType> = [];
    const updatedClasses = await Promise.all(
      (allRecClasses ?? [])
        .sort((c1: BookingEventObject, c2: BookingEventObject) => {
          return (
            convertMiliTimeToImmediateWeekDate(c1.startMili) -
            convertMiliTimeToImmediateWeekDate(c2.startMili)
          );
        })
        .sort((c1: BookingEventObject, c2: BookingEventObject) => {
          return getTagVal(c1.tag) - getTagVal(c2.tag);
        })
        .filter((c: BookingEventObject) => c.id)
        .map(async (c: BookingEventObject) => {
          if (!c.id)
            throw `Error: updateRecommendedClasses missing c.id ${c.title} ${c.startMili}`;
          const recClass = { ...c };
          const currentStart = convertMiliTimeToImmediateWeekDate(c.startMili);
          const topic = await getClassWeekTopic(c.id, currentStart);
          const theme = await getClassWeekTheme(c.id, currentStart);
          const slide = await getClassWeekCleanSlide(c.id, currentStart);
          const wantTheme = motive ? mapMotiveToTheme(motive) : undefined;

          recClass.startMili = currentStart;
          recClass.title = topic;
          recClass.theme = theme;
          recClass.cleanSlide = slide;
          if (recClass.recLevel === 'all' && cefrLevel)
            recClass.recLevel = cefrLevel;
          if (
            recClass.tag &&
            !tags.includes(recClass?.tag) &&
            (!theme ||
              (wantTheme && theme === wantTheme) ||
              wantTheme === 'any')
          ) {
            tags.push(recClass.tag);
            totalRecClasses.push(recClass);
          }

          return recClass;
        }),
    );

    totalRecClasses = totalRecClasses.sort(
      (c1: BookingEventObject, c2: BookingEventObject) => {
        const c1Val = c1.theme ? 0 : 1;
        const c2Val = c2.theme ? 0 : 1;
        return c1Val - c2Val;
      },
    );

    totalRecClasses = totalRecClasses.slice(0, (subOption ?? 0) + 1);

    setChosenSchedule(totalRecClasses);
    setRecClasses(totalRecClasses);
  }

  useEffect(() => {
    if (classes) updateRecommendedClasses();
  }, []);

  const allowBookingCountByWeek = !oneClassCap ? (subOption ?? 0) + 1 : 1;

  return (
    <div
      className={cx(
        'transition-all ease-in duration-${animationDuration} translate-x-0',
        !clicked && loaded ? 'opacity-100' : 'opacity-0 -translate-x-20',
      )}
    >
      <div className="mx-auto max-w-4xl text-center">
        <p className="mt-2  md:text-3xl text-xl font-bold tracking-tight text-gray-900">
          {`${stringsPricingPage.bookYourFirstClass}`}
        </p>
      </div>
      <p className="mx-auto md:mt-6 mt-4 mb-6 max-w-2xl text-center md:text-lg text-md text-gray-600">
        {seeCalendar ? (
          <span>
            {`${stringsPricingPage.select} `}
            {!oneClassCap ? (
              <span className="text-blue-immigo">
                {wantRecur
                  ? (subOption || 0) + 1
                  : `${stringsPricingPage.upTo} ` + ((subOption || 0) + 1) * 4}
              </span>
            ) : (
              <span>a</span>
            )}{' '}
            {subOption && subOption > 0
              ? stringsPricingPage.classesThatFitSchedule
              : stringsPricingPage.classThatFitSchedule}
          </span>
        ) : (
          stringsPricingPage.recommendFirstClass
        )}
      </p>
      {/* <div className="relative flex items-start mb-4">
        <div className="flex h-6 items-center">
          <input
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            onChange={() => {
              trackBookingAnalytics(
                wantRecur
                  ? 'Unmarked want recurring schedule on pricing page'
                  : 'Marked want recurring schedule on pricing page',
                {
                  userID: userData?.id,
                  subOption: subOption,
                },
              );
              setWantRecur(!wantRecur);
            }}
            checked={wantRecur}
            className="cursor-pointer h-4 w-4 rounded border-gray-300 text-blue-immigo focus:ring-blue-immigo"
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label htmlFor="comments" className="font-medium text-gray-900">
            I want this schedule every week
          </label>
          <p id="comments-description" className="text-gray-500">
            Having a recurring schedule is a good way to build a positive
            learning habit. You can always reschedule your class as long as you
            let us know 24 hours before the class.
          </p>
        </div>
      </div> */}

      <div className="mt-4 text-gray-500 text-xs">
        {stringsPricingPage.adjustedToYourTimeZone}
      </div>
      {!classes || recClasses.length === 0 ? (
        <div className="flex justify-center items-center">
          <ContentLoader viewBox="0 0 380 140">
            <rect x="20" y="0" rx="5" ry="5" width="40" height="120" />
            <rect x="70" y="0" rx="5" ry="5" width="40" height="120" />
            <rect x="120" y="0" rx="5" ry="5" width="40" height="120" />
            <rect x="170" y="0" rx="5" ry="5" width="40" height="120" />
            <rect x="220" y="0" rx="5" ry="5" width="40" height="120" />
            <rect x="270" y="0" rx="5" ry="5" width="40" height="120" />
            <rect x="320" y="0" rx="5" ry="5" width="40" height="120" />
          </ContentLoader>
        </div>
      ) : !seeCalendar ? (
        <div className="mt-4">
          <RecommendedBookingsContainer classes={recClasses} />
          <div className="my-2">
            <SparkleButton
              onClick={() => {
                trackBookingAnalytics(
                  'Clicked view full calendar on booking page',
                );
                setSeeCalendar(true);
              }}
              text={stringsPricingPage.doesntFitSchedule}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="h-0 min-h-[550px] bg-gray-50 rounded-md mb-2">
            <WeekBookingCalendar
              chosenSchedule={chosenSchedule}
              setChosenSchedule={setChosenSchedule}
              events={classes}
              setLoading={setLoading}
              cefr={cefr}
            />
          </div>
          <div className="my-2">
            <SparkleButton
              onClick={() => {
                trackBookingAnalytics(
                  'Clicked back to recommended classes on booking page',
                );
                updateRecommendedClasses();
                setSeeCalendar(false);
              }}
              text={stringsPricingPage.backToRecommendedClasses}
            />
          </div>
        </div>
      )}
      <div
        ref={buttonRef}
        className={cx(
          chosenSchedule.length >= 1 ? 'animate-[shake_1.5s_ease-in-out] ' : '',
        )}
      >
        <PrimaryButton
          text={
            !wantRecur && !oneClassCap
              ? chosenSchedule.length < 1
                ? stringsPricingPage.selectAtLeastOneClassToProceed
                : chosenSchedule.length < allowBookingCountByWeek * 4 &&
                  !oneClassCap &&
                  seeCalendar
                ? `${stringsPricingPage.submitMyBooking} (${
                    chosenSchedule.length
                  }/${allowBookingCountByWeek * 4} ${
                    allowBookingCountByWeek - chosenSchedule.length > 1
                      ? stringsPricingPage.classesBooked
                      : stringsPricingPage.classBooked
                  })`
                : chosenSchedule.length > allowBookingCountByWeek * 4 &&
                  !oneClassCap
                ? `${stringsPricingPage.pleaseDeselect} ${
                    chosenSchedule.length - allowBookingCountByWeek * 4
                  } ${stringsPricingPage.classesToProceed}`
                : stringsPricingPage.submitMyBooking
              : chosenSchedule.length < allowBookingCountByWeek
              ? `${stringsPricingPage.select} ${
                  allowBookingCountByWeek - chosenSchedule.length
                } ${
                  allowBookingCountByWeek - chosenSchedule.length > 1
                    ? stringsPricingPage.moreClassesToProceed
                    : stringsPricingPage.moreClassToProceed
                }`
              : chosenSchedule.length > allowBookingCountByWeek
              ? `${stringsPricingPage.pleaseDeselect} ${
                  chosenSchedule.length - allowBookingCountByWeek
                } ${stringsPricingPage.classesToProceed}`
              : stringsPricingPage.submitMyBooking
          }
          onClick={() => {
            setClicked(true);
            trackBookingAnalytics('Submitted booking on pricing page', {
              userID: userData?.id,
              subOption: subOption,
            });
            setTimeout(() => {
              setPage(userData ? 9 : 8);
            }, 300);
            onBookingSubmit();
          }}
          disabled={
            (!wantRecur &&
              chosenSchedule.length > ((subOption ?? 0) + 1) * 4) ||
            (!wantRecur && chosenSchedule.length < 1) ||
            (wantRecur && chosenSchedule.length < (subOption ?? 0) + 1) ||
            (wantRecur && chosenSchedule.length > (subOption ?? 0) + 1) ||
            (oneClassCap && chosenSchedule.length !== 1)
          }
        />
      </div>
      <div className="mb-10">
        <div
          className={cx(
            'text-blue-immigo ring-1 ring-inset ring-blue-immigo hover:ring-blue-immigo-lighter',
            'cursor-pointer mt-2 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-immigo',
          )}
          onClick={() => {
            setClicked(true);
            trackBookingAnalytics('Clicked choose a different learning plan', {
              userID: userData?.id,
              subOption: subOption,
            });
            setTimeout(() => {
              setPage(6);
            }, animationDuration);
          }}
        >
          {stringsPricingPage.chooseADiferentLearningPlan}
        </div>
      </div>
    </div>
  );
}
