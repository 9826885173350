import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Phone number modal shown on the pricing page'
  | 'Phone number modal closed on the pricing page'
  | 'Submitted phone number on phone number modal in pricing page'
  | 'Selected motivation on pricing page'
  | 'Selected nationality on pricing page'
  | 'Selected based country on pricing page'
  | 'Selected CEFR Level on pricing page'
  | 'Entered vision page on pricing page'
  | 'Click continue on vision page on pricing page'
  | 'Opened waitlist modal'
  | 'Submitted phone number in waitlist modal'
  | 'Entered loading page on pricing page'
  | 'Opened nationality modal on pricing page'
  | 'Closed nationality modal on pricing page'
  | 'Opened based country modal on pricing page'
  | 'Closed based country modal on pricing page'
  | 'Played CEFR audio in pricing page'
  | 'Played CEFR video in vision page in pricing page'
  | 'Opened level waitlist modal'
  | 'Submitted level waitlist modal';

const version = 'v1-spanish-translate';
const usrlang = navigator.language;

type PricingPageData = {
  userID?: string;
  email?: string;
  startMili?: number;
  title?: string;
  cefrLevel?: string;
  motivation?: string;
  nationality?: string;
  basedCountry?: string;
  enteredValue?: string;
};

export const trackPricingPageEvents = (
  event: Events,
  data: PricingPageData = {},
) => {
  mixpanel.track(event, { ...data, version: version, lang: usrlang });
};
