import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  activateUserSubscription,
  cancelUserSubscription,
  getCustomerSubscriptionInformation,
  getSubscriptionProductInformation,
  pauseUserSubscription,
  resumeUserSubscription,
} from '../../../api';
import { overrideCreditToUser } from '../../../firebase/configuration';
import { SubscriptionData } from '../../../types/stripe';
import { PrimaryButton } from '../../buttons/primary';
import { WarningButton } from '../../buttons/warning';
import TextInput from '../../forms/textInput/index';
import { SecondaryBlueButton } from '../../buttons/secondaryBlue';
import { hourInMili, monthInMili, weekInMili } from '../../../usefuldata/mili';
import { CancelSubscriptionQuestionModal } from '../../modals/cancelSubscriptionQuestionModal/cancelSubscriptionQuestionModal';
import { createSurveyDocument } from '../../../firebase/survey/survey';
import { getFinalPriceGivenPriceIDAndCouponID } from '../../../config/stripe/price';
import { currencySymbols } from '../../../util/currency';
import { SparkleButton } from '../../buttons/sparkle/sparkleButton';
import { PaymentMethodsModal } from '../../modals/paymentMethodsModal/paymentMethodsModal';
import {
  awardStickAroundCredit,
  detachPaymentMethod,
  getCoupon,
  getSetUpIntent,
  getStripeCustomer,
  resetBillingCycle,
  updateCustomerDefaultPaymentMethod,
} from '../../../api/payment';
import { CancelSubscriptionAreYouSureModal } from '../../modals/cancelSubscriptionAreYouSureModal/cancelSubscriptionAreYouSureModal';
import { trackSubscriptionPageData } from '../../../pages/Subscription/analytics';
import { capitalizeFirstLetter } from '../../../util/standardization';
import { getUserBalanceHistory } from '../../../firebase/balance/balance';
import {
  getNextPendingHook,
  getPendingHooks,
  getUserHooks,
} from '../../../firebase/hooks/hooks';
import { SubscriptionInfoLoader } from './loaders/info';

export const SubscriptionInformation = (props: {
  userData: any;
  onAction?: Function;
  onActionComplete?: Function;
  isAdmin?: boolean;
}) => {
  const { userData, onAction, onActionComplete, isAdmin } = props;

  const [subscriptionInformation, setsubscriptionInformation] = useState<
    undefined | SubscriptionData
  >();
  const [cancelationModalOpen, setCancelationModalOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useState<any>(undefined);
  const [cancelationAreYouSureModalOpen, setCancelationAreYouSureModalOpen] =
    useState(false);

  const [credit, setCredit] = useState<number>(0);
  const [customerData, setCustomerData] = useState<any>();
  const [balance, setBalance] = useState<number | undefined>();
  const [alreadyGotFreeMonth, setAlreadyGotFreeMonth] = useState(true);

  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);
  const [nextCreditUpdateMili, setNextCreditUpdateMili] = useState();

  const [nextInvoiceAmount, setNextInvoiceAmount] = useState<
    number | undefined
  >();

  const customerID = userData?.customerID;

  const urlParams = new URLSearchParams(window.location.search);
  const setupIntentId = urlParams.get('setup_intent');

  useEffect(() => {
    if (userData?.credit !== undefined) setCredit(userData.credit);
    if (customerID && userData?.id) fetchSubscriptionInformation();
  }, [userData]);

  useEffect(() => {
    if (customerData && setupIntentId)
      retrievePaymentIntentAndUpdatePaymentMethod(setupIntentId);
  }, [customerData]);

  async function fetchSubscriptionInformation() {
    const subscriptionInfo = await getCustomerSubscriptionInformation(
      customerID,
    );
    const balanceHistory = await getUserBalanceHistory(userData.id);
    const customer = await getStripeCustomer(customerID);
    const nextCreditUpdateMili = (
      await getNextPendingHook(userData?.id, 'credit-update')
    )?.postAt;

    setNextCreditUpdateMili(nextCreditUpdateMili);
    setCustomerData(customer);
    if (customer?.balance) {
      setBalance(customer?.balance / 100);
    }

    let freeMonthFlag = false;
    balanceHistory?.forEach((b) => {
      if (b.reason === 'cancel-free-month') freeMonthFlag = true;
    });
    setAlreadyGotFreeMonth(freeMonthFlag);

    console.log(subscriptionInfo);
    let invoiceAmount = 0;
    if (
      subscriptionInfo &&
      subscriptionInfo.plan &&
      subscriptionInfo.plan.product
    ) {
      const productInfo = await getSubscriptionProductInformation(
        subscriptionInfo.plan.product,
      );
      subscriptionInfo.id = subscriptionInfo.subID;
      subscriptionInfo.plan.name = productInfo?.name;
      invoiceAmount = subscriptionInfo.plan.amount / 100;

      if (subscriptionInfo.customerData?.paymentMethods) {
        setPaymentMethods(subscriptionInfo.customerData?.paymentMethods);
        let newDefault =
          subscriptionInfo.customerData?.paymentMethods?.[0]?.card;
        subscriptionInfo.customerData?.paymentMethods.forEach((p: any) => {
          if (p.id === customer?.invoice_settings?.default_payment_method) {
            newDefault = p?.card;
          }
        });
        console.log('lol');
        setDefaultPaymentMethod(newDefault);
      }
    }

    let amountOff = 0;
    if (subscriptionInfo?.discount?.coupon?.id) {
      const coupon = await getCoupon(subscriptionInfo.discount.coupon.id);
      console.log(subscriptionInfo.discount.coupon.id);
      console.log(coupon);
      amountOff = coupon
        ? (coupon.amount_off ?? invoiceAmount * coupon.percent_off) / 100
        : 0;
    }

    console.log(subscriptionInfo);
    console.log(customer?.balance);
    setNextInvoiceAmount(invoiceAmount - amountOff + customer?.balance / 100);
    setsubscriptionInformation(subscriptionInfo);
  }

  async function onCreditEditSubmit() {
    await overrideCreditToUser(userData.id, credit);
  }

  async function onDeactivate(
    reasons: Array<string>,
    additionalExplanation: string,
  ) {
    if (subscriptionInformation?.subID) {
      if (onAction) onAction();
      await createSurveyDocument(userData?.id, {
        type: 'cancel-reason',
        tags: reasons,
        additionalDetail: additionalExplanation,
      });
      await cancelUserSubscription(subscriptionInformation?.subID);
      await fetchSubscriptionInformation();
      if (onActionComplete) onActionComplete();
    }
  }

  async function onReactivate() {
    trackSubscriptionPageData('Clicked resume subscription');
    if (subscriptionInformation?.subID) {
      if (onAction) onAction();
      await activateUserSubscription(subscriptionInformation?.subID);
      await fetchSubscriptionInformation();
      if (onActionComplete) onActionComplete();
    }
  }

  async function onPause() {
    trackSubscriptionPageData('Clicked skip next billing cycle clicked');
    if (subscriptionInformation?.subID) {
      if (onAction) onAction();
      await pauseUserSubscription(
        subscriptionInformation?.subID,
        new Date().valueOf() + 5 * weekInMili,
      );
      await fetchSubscriptionInformation();
      if (onActionComplete) onActionComplete();
    }
  }

  async function onResume() {
    trackSubscriptionPageData('Clicked resume subscription (unpause)');
    if (subscriptionInformation?.subID) {
      if (onAction) onAction();
      await resumeUserSubscription(subscriptionInformation?.subID);
      await fetchSubscriptionInformation();
      if (onActionComplete) onActionComplete();
    }
  }

  async function onResetBilling() {
    trackSubscriptionPageData('Clicked reset billing button');
    if (subscriptionInformation?.subID) {
      if (onAction) onAction();
      await resetBillingCycle(subscriptionInformation?.subID);
      await fetchSubscriptionInformation();
      if (onActionComplete) onActionComplete();
    }
  }

  function getNextBilingDate() {
    let nextPeriod = (subscriptionInformation?.current_period_end ?? 0) * 1000;
    // Paused
    if (subscriptionInformation?.pause_collection?.resumes_at) {
      while (
        nextPeriod <
        subscriptionInformation.pause_collection.resumes_at * 1000
      ) {
        nextPeriod += 4 * weekInMili;
      }
    }

    return nextPeriod;
  }

  function getNextCreditUpdate() {
    const interval = subscriptionInformation?.plan?.interval;
    let date = getNextBilingDate();
    if (nextCreditUpdateMili) return nextCreditUpdateMili;
    if (
      interval === 'year' &&
      subscriptionInformation?.current_period_start &&
      subscriptionInformation?.current_period_end
    ) {
      date = subscriptionInformation?.current_period_start * 1000;
      while (date < new Date().valueOf()) {
        date = date + 4 * weekInMili;
      }
    }

    return date;
  }

  async function onGetFree() {
    onAction?.();
    trackSubscriptionPageData(
      'Cancelation "Are you sure" modal clicked get free clicked',
    );
    if (subscriptionInformation && userData?.id && nextInvoiceAmount) {
      await awardStickAroundCredit(
        customerID,
        subscriptionInformation?.currency,
        nextInvoiceAmount * 100,
        'cancel-free-month',
        userData?.id,
      );
      // await resetBillingCycle(subscriptionInformation?.subID); // Reset billing so that they can take more classes
      await fetchSubscriptionInformation();
      setCancelationAreYouSureModalOpen(false);
    }
    onActionComplete?.();
  }

  async function onPaymentMethodsSave(paymentMethodID: string) {
    onAction?.();
    trackSubscriptionPageData('Clicked save payment method');
    await updateCustomerDefaultPaymentMethod(customerID, paymentMethodID);
    onActionComplete?.();
    window.location.href = '/subscription';
  }

  async function onPaymentMethodDelete(paymentMethodID: string) {
    onAction?.();
    await detachPaymentMethod(paymentMethodID);
    onActionComplete?.();
    window.location.href = '/subscription';
  }

  async function retrievePaymentIntentAndUpdatePaymentMethod(setiId: string) {
    const setupIntent = await getSetUpIntent(setiId); // retrieve setup intent
    if (setupIntent?.payment_method) {
      await onPaymentMethodsSave(setupIntent?.payment_method); // update default payment method
    }
  }

  return (
    <>
      {/* {subscriptionInformation?.current_period_start &&
      subscriptionInformation?.plan?.interval !== 'year' &&
      subscriptionInformation?.current_period_start * 1000 + hourInMili * 24 <=
        new Date().valueOf() ? ( // Show only when the billing started not long ago
        <div
          className="
        shadow-lg w-full border-2 p-3
        font-medium bg-gradient-to-r from-indigo-400 to-cyan-400
        rounded-lg
        mb-2"
        >
          <div className="text-white text-xl">
            Want to take your classes sooner but have no more class credit?
          </div>
          <div className="text-gray-300">
            Reset your billing cycle and pay for your subscription now to start
            taking classes
          </div>
          <div className="max-w-[200px] ml-auto">
            <SparkleButton
              text="Reset billing cycle"
              onClick={async () => {
                await onResetBilling();
              }}
            />
          </div>
        </div>
      ) : null} */}
      <div
        className="
      shadow-lg w-full border-2 p-3
      font-medium bg-white
      rounded-lg
      mb-10"
      >
        <CancelSubscriptionQuestionModal
          isOpen={cancelationModalOpen}
          onBack={() => {
            setCancelationModalOpen(false);
          }}
          onOpen={() => {
            setCancelationModalOpen(true);
          }}
          onCancel={async (
            reasons: Array<string>,
            additionalExplanation: string,
          ) => {
            await onDeactivate(reasons, additionalExplanation);
          }}
          onClose={() => {
            setCancelationModalOpen(false);
          }}
        />
        <PaymentMethodsModal
          returnUrl={`${window.location.origin}/subscription`}
          paymentMethods={paymentMethods.map((p: any) => {
            const card = p?.card;
            return {
              lastFour: card?.last4,
              brand: card?.brand,
              expMonth: card?.exp_month,
              expYear: card?.exp_year,
              id: p?.id,
            };
          })}
          isOpen={openPaymentMethodModal}
          onSave={onPaymentMethodsSave}
          onDelete={onPaymentMethodDelete}
          onClose={() => {
            setOpenPaymentMethodModal(false);
          }}
          defaultID={customerData?.invoice_settings?.default_payment_method}
        />
        <CancelSubscriptionAreYouSureModal
          onClose={() => {
            trackSubscriptionPageData('Closed "Are you sure" modal');
            setCancelationAreYouSureModalOpen(false);
          }}
          onGetFree={onGetFree}
          onCancelAnyways={() => {
            trackSubscriptionPageData(
              'Cancelation "Are you sure" modal clicked "cancel anyways"',
            );
            setCancelationAreYouSureModalOpen(false);
            setCancelationModalOpen(true);
          }}
          isOpen={cancelationAreYouSureModalOpen}
        />

        <div className="px-4 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Your Learning Plan
          </h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            Information about your subscription with us.
          </p>
        </div>
        <div className="mt-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Learning plan
              </dt>
              {subscriptionInformation?.plan.name ? (
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {subscriptionInformation?.plan.name}
                </dd>
              ) : (
                <SubscriptionInfoLoader />
              )}
            </div>
            {!subscriptionInformation?.cancel_at_period_end &&
            userData?.product?.metadata?.credits !== 'inf' ? (
              <>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Next credit update
                  </dt>
                  {getNextCreditUpdate() ? (
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {moment(getNextCreditUpdate()).format(
                        'MMMM DD, YYYY - HH:mm:SS',
                      )}
                    </dd>
                  ) : (
                    <SubscriptionInfoLoader />
                  )}
                </div>
              </>
            ) : null}
            {!subscriptionInformation?.cancel_at_period_end ? (
              <>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Next billing date
                  </dt>
                  {getNextBilingDate() ? (
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {moment(getNextBilingDate()).format('MMMM DD, YYYY')}
                    </dd>
                  ) : (
                    <SubscriptionInfoLoader />
                  )}
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Next invoice
                  </dt>
                  {subscriptionInformation ? (
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {/* {`$${
                    (subscriptionInformation?.plan.amount ?? 0) / 100 -
                    (subscriptionInformation?.discount?.coupon?.amount_off ??
                      0) /
                      100
                  }`} */}
                      {subscriptionInformation
                        ? `
                    ${subscriptionInformation?.currency?.toLocaleUpperCase()} 
                    ${currencySymbols[subscriptionInformation?.currency]}
                    ${nextInvoiceAmount?.toFixed(2)}`
                        : 0}
                    </dd>
                  ) : (
                    <SubscriptionInfoLoader />
                  )}
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Payment methods
                  </dt>
                  {defaultPaymentMethod ? (
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <span className="flex justify-between">
                        <span>{`${capitalizeFirstLetter(
                          defaultPaymentMethod?.brand ?? '',
                        )} •••• ${defaultPaymentMethod?.last4}  
                        ${defaultPaymentMethod?.exp_month}/${
                          defaultPaymentMethod?.exp_year
                        }`}</span>
                        <span
                          onClick={() => {
                            trackSubscriptionPageData(
                              'Clicked view payment method',
                            );
                            setOpenPaymentMethodModal(true);
                          }}
                          className="cursor-pointer hover:text-blue-immigo"
                        >
                          View my payment methods
                        </span>
                      </span>
                    </dd>
                  ) : (
                    <SubscriptionInfoLoader />
                  )}
                </div>
              </>
            ) : (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Learning end date
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {moment(
                    (subscriptionInformation?.cancel_at ?? 0) * 1000,
                  ).format('MMMM DD, YYYY')}
                </dd>
              </div>
            )}
            {(subscriptionInformation?.trial_end ?? 0) * 1000 -
              new Date().valueOf() >
            0 ? (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Trial end date
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {moment(
                    (subscriptionInformation?.trial_end ?? 0) * 1000,
                  ).format('MMMM DD, YYYY')}
                </dd>
              </div>
            ) : null}
          </dl>
        </div>

        {customerData ? (
          <div className="flex">
            <div className="mt-8 max-w-[200px] ml-auto mr-2">
              {!subscriptionInformation?.cancel_at_period_end ? (
                subscriptionInformation?.pause_collection ? (
                  <PrimaryButton
                    text="Resume subscription"
                    onClick={onResume}
                  />
                ) : (
                  <SecondaryBlueButton
                    disabled={balance !== undefined && balance < 0}
                    text="Skip next billing cycle"
                    onClick={onPause}
                  />
                )
              ) : null}
            </div>
            <div className="mt-8 max-w-[180px]">
              {subscriptionInformation?.cancel_at_period_end ? (
                <PrimaryButton
                  text="Resume subscription"
                  onClick={onReactivate}
                />
              ) : (
                <WarningButton
                  text="Cancel subscription"
                  onClick={() => {
                    trackSubscriptionPageData('Clicked cancel subscription');
                    if (!alreadyGotFreeMonth)
                      setCancelationAreYouSureModalOpen(true);
                    else setCancelationModalOpen(true);
                  }}
                />
              )}
            </div>
          </div>
        ) : null}
        {isAdmin ? (
          <div className="w-24 ml-auto">
            <TextInput
              type={'number'}
              title="Edit credit"
              value={credit}
              onChange={(value: string) => setCredit(parseInt(value))}
            />
            <div
              onClick={onCreditEditSubmit}
              className="rounded-lg p-1 bg-blue-immigo text-center text-white cursor-pointer"
            >
              Submit
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
