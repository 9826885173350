import { useEffect, useState } from 'react';
import { ImprovementReportModal } from '../../../components/modals/improvementReportModal/improvementReportModal';
import {
  ImprovementPeerRating,
  ImprovementPeerRatingEventObject,
} from '../../../types/postClassSurvey/rating';
import { returnUserByUID } from '../../../firebase/configuration';
import { UserData } from '../../../types/user';
import { getPostClassImprovementReportUser } from '../../../api/postClassSurvey';
import {
  getUserPopUpStatus,
  recordPopUpClose,
  recordPopUpOpen,
} from '../../../api/popUpStatus';
import { weekInMili } from '../../../usefuldata/mili';
import { trackImprovementReportNotifier } from './analytics';

const example: Array<ImprovementPeerRating> = [
  {
    reportedBy: '55GfqR1eKVQmzqe11XFdYvpjWyg2',
    reportee: '',
    classSessionId: '',
    improvedTags: ['grammar', 'confidence', 'pronunciation'],
    createdAt: 1733446751161,
  },
  {
    reportedBy: '55GfqR1eKVQmzqe11XFdYvpjWyg2',
    reportee: '',
    classSessionId: '',
    improvedTags: ['grammar', 'pronunciation', 'word-range'],
    createdAt: 1733446751161,
  },
  {
    reportedBy: '55GfqR1eKVQmzqe11XFdYvpjWyg2',
    reportee: '',
    classSessionId: '',
    improvedTags: ['grammar', 'pronunciation'],
    createdAt: 1733446751161,
  },
  {
    reportedBy: '55GfqR1eKVQmzqe11XFdYvpjWyg2',
    reportee: '',
    classSessionId: '',
    improvedTags: ['grammar', 'pronunciation'],
    createdAt: 1733446751161,
  },
  {
    reportedBy: '55GfqR1eKVQmzqe11XFdYvpjWyg2',
    reportee: '',
    classSessionId: '',
    improvedTags: ['grammar', 'pronunciation'],
    createdAt: 1733446751161,
  },
  {
    reportedBy: '55GfqR1eKVQmzqe11XFdYvpjWyg2',
    reportee: '',
    classSessionId: '',
    improvedTags: ['grammar', 'pronunciation'],
    createdAt: 1733446751161,
  },
  {
    reportedBy: '55GfqR1eKVQmzqe11XFdYvpjWyg2',
    reportee: '',
    classSessionId: '',
    improvedTags: ['grammar', 'pronunciation'],
    createdAt: 1733446751161,
  },
  {
    reportedBy: '55GfqR1eKVQmzqe11XFdYvpjWyg2',
    reportee: '',
    classSessionId: '',
    improvedTags: ['grammar', 'pronunciation'],
    createdAt: 1733446751161,
  },
];

export const ImprovementReportNotifier = (props: { uid: string }) => {
  const { uid } = props;
  const [ratings, setRatings] = useState<
    undefined | Array<ImprovementPeerRatingEventObject>
  >();
  const [openModal, setOpenModal] = useState(false);

  async function onLoad() {
    const reportList = await getPostClassImprovementReportUser(
      'SVl5gNZ2ufX4MEZOQtGTJXo3AN12',
    );
    const popUpStatusReport: PopUpStatus = await getUserPopUpStatus(
      uid,
      'peer-improvement-report',
    );

    const timeLimit = new Date().valueOf() - 2 * weekInMili;

    const processedList: Array<ImprovementPeerRatingEventObject> =
      await Promise.all(
        reportList
          .filter(
            (report: ImprovementPeerRating) => report.createdAt >= timeLimit,
          )
          .map(async (rating: ImprovementPeerRating) => {
            const reportedUser: UserData = (await returnUserByUID(
              rating.reportedBy,
            )) as UserData;
            const processedData: ImprovementPeerRatingEventObject = {
              ...rating,
            };
            processedData.reportedByProfilePictureUrl =
              reportedUser?.profilePictureLink;
            processedData.reportedByName = reportedUser.name;
            return processedData;
          }),
      );
    console.log(processedList);
    if (
      !popUpStatusReport?.lastOpened ||
      popUpStatusReport?.lastOpened < timeLimit
    ) {
      if (reportList?.length > 0) {
        setOpenModal(true);
        trackImprovementReportNotifier(
          'Improvement report notifier modal opened',
          {
            reportNumber: reportList?.length,
            lastOpened: popUpStatusReport?.lastOpened,
          },
        );
        await recordPopUpOpen(uid, 'peer-improvement-report');
      }
    }
    setRatings(processedList);
  }

  async function onCloseModal() {
    trackImprovementReportNotifier(
      'Improvement report notifier modal closed',
      {},
    );
    await recordPopUpClose(uid, 'peer-improvement-report');
    setOpenModal(false);
  }

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      {ratings ? (
        <ImprovementReportModal
          isOpen={openModal}
          onCloseModal={onCloseModal}
          ratings={ratings}
        />
      ) : null}
    </>
  );
};
