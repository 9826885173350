import PeopleBoxOption from '../../../../../../components/forms/peopleBoxOption/peopleBoxOption';
import PeopleTableUpDownSame from '../../../../../../components/forms/peopleTableUpDownSameOption/peopleTableUpDownSame';
import { BookerData } from '../../../../../../types/bookings';
import { ImprovementRatingMap } from '../../../../../../types/postClassSurvey/rating';

const classTypeToColor: any = {
  idiom: 'green',
  vocab: 'yellow',
  pronunciation: 'orange',
  'free-talking': 'blue',
  grammar: 'red',
};

const optionList = [
  {
    id: 'grammar',
    value: 'grammar',
    style: 'text-red-500 border-red-500 font-semibold hover:text-red-300',
    selectedStyle:
      'bg-red-500 text-white border-red-500 font-semibold hover:text-red-300',
  },
  {
    id: 'pronunciation',
    value: 'pronunciation',
    style:
      'text-orange-500 border-orange-500 font-semibold hover:text-orange-300',
    selectedStyle:
      'bg-orange-500 text-white border-orange-500 font-semibold hover:text-orange-300',
  },
  {
    id: 'confidence',
    value: 'confidence',
    style: 'text-blue-500 border-blue-500 font-semibold hover:text-blue-300',
    selectedStyle:
      'bg-blue-500 text-white border-blue-500 font-semibold hover:text-blue-300',
  },
  {
    id: 'word-range',
    value: 'word range',
    style:
      'text-yellow-500 border-yellow-500 font-semibold hover:text-yellow-300',
    selectedStyle:
      'bg-yellow-500 text-white border-yellow-500 font-semibold hover:text-yellow-300',
  },
];

export const PeerImprovement = (props: {
  chosenMembers: Array<BookerData>;
  onRatingChange: Function;
  ratingMap: ImprovementRatingMap;
}) => {
  const { chosenMembers, onRatingChange, ratingMap } = props;
  return (
    <div>
      <div className="text-gray-500 mb-2">
        (Only if you took another class with them) What did they improve this
        class compared to the last class?
      </div>
      <PeopleBoxOption
        userList={chosenMembers}
        optionStatusMap={ratingMap}
        title="You can choose multiple options."
        onOptionChange={onRatingChange}
        optionList={optionList}
        multiple
      />
    </div>
  );
};
