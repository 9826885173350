import CountUp from 'react-countup';
import { SimpleBackAndNext } from '../../../components/buttons/simpleBackAndNext/simpleBackAndNext';
import { UserData } from '../../../types/user';
import { countryCodeToSVG } from '../../../util/flagLoader';
import AvatarGroupStacked from '../../../components/avatar/avatarGroupStacked/avatarGroupStacked';
import AvatarGroupStackedBigger from '../../../components/avatar/avatarGroupStackedBigger/avatarGroupStacked';
import { ImmigoWhiteLogo } from '../../../components/icons/immigoWhite';
import { ScreenShotButton } from '../../../components/buttons/screenShot/screenShotButton';
import { trackYearlyInsight } from '../../../features/YearlyInsight/analytics';
import { useEffect } from 'react';

export const YearlyInsightFriends = (props: {
  listOfCountries?: Array<string>;
  metList?: Array<UserData>;
  metUsersCount?: number;
  onNext: Function;
  onBack: Function;
}) => {
  const { onNext, onBack, metList, listOfCountries, metUsersCount } = props;
  const usersWithPics = metList?.filter((u) => u.profilePictureLink) ?? [];
  useEffect(() => {
    trackYearlyInsight('Visited wrapped friends page');
  });

  return (
    <div
      className="text-center flex flex-col items-center justify-center md:py-24 bg-gradient-to-r from-pink-400 to-blue-500 h-full 
    bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%"
    >
      <div className="w-48 flex">
        <ImmigoWhiteLogo />
      </div>
      <ScreenShotButton
        onClick={() => {
          trackYearlyInsight('Wrapped share with friends clicked', {
            page: 'friends',
          });
        }}
      />
      <div className="font-bold text-3xl md:text-6xl mb-10 text-white max-w-[700px]">
        Through Immigo, you've met{' '}
        <span className="text-yellow-200">
          <CountUp end={metUsersCount ?? 0} />
        </span>{' '}
        other English learners across{' '}
        <span className="text-yellow-200">
          <CountUp end={listOfCountries?.length ?? 0} />
        </span>{' '}
        different countries.
      </div>

      <div className="mb-8 md:mb-16">
        <AvatarGroupStackedBigger
          imageLinks={usersWithPics.map((u) => u.profilePictureLink)}
          names={usersWithPics.map((u) => u.name)}
          ids={usersWithPics.map((u) => u.id)}
          nationalities={usersWithPics.map((u) => u.nationality ?? '')}
          limit={8}
        />
      </div>
      <div className="flex max-w-[300px] flex-wrap text-center justify-center">
        {listOfCountries?.map((country) => {
          return (
            <img
              className="md:w-10 w-8 h-auto mr-2 my-1"
              src={countryCodeToSVG(country)}
            />
          );
        })}
      </div>
      <div data-html2canvas-ignore="true">
        <SimpleBackAndNext onNext={onNext} onBack={onBack} color="text-white" />
      </div>
    </div>
  );
};
