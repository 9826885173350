import { UserData } from '../../../types/user';
import { getObscuredName } from '../../../util/standardization';
import { Avatar } from '../../avatar/avatar';
import { cx } from 'classix';

interface PeopleBoxOptionObject {
  id: string;
  value: string;
  style?: string;
  selectedStyle?: string;
}

export default function PeopleBoxOption(props: {
  userList: Array<Partial<UserData>>;
  title?: string;
  optionStatusMap?: any;
  onOptionChange: Function;
  optionList: Array<PeopleBoxOptionObject>;
  multiple?: boolean;
}) {
  const {
    userList,
    title,
    onOptionChange,
    optionList,
    optionStatusMap,
    multiple,
  } = props;

  function optionChangeHandler(uid: string, chosen: string) {
    onOptionChange?.(uid, chosen);
  }

  return (
    <fieldset>
      <legend className="text-base font-semibold leading-6 text-gray-900">
        {title}
      </legend>
      <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200 max-h-[500px] overflow-scroll px-2">
        {userList.map((person, personIdx) => (
          <div key={personIdx} className="relative flex items-center py-4">
            <div className="min-w-0 flex-1 text-sm leading-6 flex items-center">
              <Avatar photoUrl={person.profilePictureLink} />
              <label
                htmlFor={`person-${person.id}`}
                className="select-none font-medium text-gray-900 ml-2"
              >
                {getObscuredName(person.name ?? '')}
              </label>
            </div>
            <div className="ml-3 flex items-center">
              {optionList.map((option: PeopleBoxOptionObject) => {
                return (
                  <span
                    key={option.value}
                    className={cx(
                      'border-2 rounded-md p-1 text-xs',
                      'cursor-pointer mx-1 text-center flex justify-center items-center',
                      (
                        multiple
                          ? optionStatusMap?.[person?.id ?? '']?.includes(
                              option.id,
                            )
                          : optionStatusMap?.[person?.id ?? ''] === option.id
                      )
                        ? option.selectedStyle
                        : option.style,
                    )}
                    onClick={() => {
                      if (person?.id)
                        optionChangeHandler(person?.id, option.id);
                    }}
                  >
                    {option.value}
                  </span>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
}
