import { useSelector } from 'react-redux';
import { UserData } from '../../../../types/user';
import { getAllGuestPasses } from '../../../../api/guestPass';
import { useEffect, useState } from 'react';
import { GuestPass } from '../../../../types/guestPass';
import BasicStatusTable from '../../../../components/tables/basicStatusTable/basicStatusTable';
import moment from 'moment-timezone';

export const GuestPassesContainer = () => {
  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  const [passes, setPasses] = useState<Array<GuestPass>>([]);

  useEffect(() => {
    console.log(userData);
    if (userData?.guestPasses) setPasses(userData.guestPasses);
  }, [userData]);

  function statusToColor(status: string) {
    switch (status) {
      case 'unused':
        return 'green';
      case 'invite-sent':
        return 'blue';
      case 'expired':
        return 'gray';
    }
  }

  return (
    <div>
      <div className="bg-gradient-to-r from-cyan-500 to-blue-500 text-white p-2 rounded-md mt-2">
        Use the booking calendar to invite your friend to your Immigo class!
      </div>
      <div className="max-h-[250px] overflow-y-scroll">
        <BasicStatusTable
          items={passes.map((pass, index) => {
            return {
              id: pass.id ?? index,
              status: statusToColor(pass.status) ?? '',
              statusText: pass.status,
              text: 'Guest Pass',
              secondText: 'Welcome Gift',
              description: `Awarded: ${moment(pass.createdAt).format(
                'MMM DD YYYY',
              )}`,
              secondDescription: `Expires: ${moment(pass.expires).format(
                'MMM DD YYYY hh:mm A',
              )}`,
            };
          })}
        />
      </div>
    </div>
  );
};
