import emptyAvatar from '../../../images/empty-avatar.webp';

export const ActivityEventContainer = (props: {
  id: string;
  uid: string;
  content: string;
  date: string;
  iconPictureURL?: string;
  timelineLength: number;
  eventIndex: number;
  onActivityAvatarClicked?: Function;
  href?: string;
  target?: string;
}) => {
  const {
    id,
    uid,
    content,
    date,
    iconPictureURL,
    timelineLength,
    eventIndex,
    href,
    target,
    onActivityAvatarClicked,
  } = props;

  return (
    <li key={id}>
      <div className="relative pb-8">
        {eventIndex !== timelineLength - 1 ? (
          <span
            className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        ) : null}
        <div className="relative flex space-x-3">
          <div>
            <img
              className="cursor-pointer object-cover object-center bg-white h-8 w-8 rounded-full flex items-center justify-center ring-1 ring-blue-light"
              src={iconPictureURL ?? emptyAvatar}
              onClick={() => {
                onActivityAvatarClicked?.();
                window.open(`profile/${uid}`, '_blank');
              }}
            />
          </div>
          <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
            <div>
              <p className="text-sm text-gray-500">
                {content}{' '}
                <a href={href} className="font-medium text-gray-900">
                  {target}
                </a>
              </p>
            </div>
            <div className="whitespace-nowrap text-right text-sm text-gray-500">
              <time dateTime={date}>{date} ago</time>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};
