import { lazy, Suspense, useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import 'react-circular-progressbar/dist/styles.css';

import { Redirect, Route, Switch } from 'react-router-dom';
import { LandingPage } from './pages/Landing/LandingPage';
import { Login } from './pages/Login/LoginPage';
import { withAuthentication } from './sessions/withAuthentication';
import { CourseList } from './pages/CourseList/CourseListPage';
import { AdminDataView } from './pages/AdminDataView/AdminDataViewPage';
import { AdminStudentDataView } from './pages/AdminDataView/AdminStudentDataView/AdminStudentDataViewPage';
import { CourseRegistration } from './pages/CourseRegistration/CourseRegistrationPage';
import { YearBook } from './pages/YearBook/YearBookPage';
import { ProfilePage } from './pages/ProfilePage/ProfilePage';
import { Subscription } from './pages/Subscription/SubscriptionPage';
import { ApplicationView } from './pages/AdminDataView/Applications/ApplicationViewPage';
import { AdditionalInfo } from './pages/AdditionalInfo/AdditionalInfoPage';
import { useSelector } from 'react-redux';
import { UserData } from './types/user';
import { ReportCardPageIndexPage } from './features/ReportCard/pages/ReportCardIndexPage';
import { initializeFirebase } from './firebase/configValues';
import { onAuthStateChanged } from 'firebase/auth';
import { AppLayout, Content } from './components/Layout/AppLayout';
import { Homepage } from './pages/Homepage/Homepage';
import { ReferralProvider } from './providers/referral-provider';
import { DiscountCodes } from './pages/DiscountCodes/DiscountCodes';
import { AvailabilityPage } from './pages/Availability/AvailabilityPage';
import { ReportCardView } from './pages/AdminDataView/ReportCard/index';
import { AchievemnetNotifier } from './features/Achievements/achievementNotifier/achievementNotifier';
import { PricingPage } from './pages/PricingPage/PricingPage';
import { PricingPageKorea } from './pages/PricingPageKorea/PricingPageKorea';
import ClassSchedule from './pages/AdminClassesView/ClassSchedule/ClassSchedule';
import ClassEdit from './pages/AdminClassesView/ClassEdit/ClassEdit';
import { ClassLinkRedirect } from './pages/ClassLinkRedirect/ClassLinkRedirect';
import { ProfileSetup } from './pages/ProfileSetup/ProfileSetup';
import MyClasses from './pages/MyClasses/MyClasses';
import { OnBoard } from './pages/OnBoard/OnBoard';
import { FeedbackListener } from './pages/FeedbackListener/FeedbackListener';
import { ClassPreview } from './pages/ClassPreview/ClassPreview';
import { NumbersPage } from './pages/Numbers';
import { SchedulePage } from './pages/SchedulePage/SchedulePage';
import { ClassInsight } from './pages/Insight/ClassInsight';
import { YearlyInsight } from './pages/YearlyInsight/YearlyInsight';
import { RankingPage } from './pages/Ranking/Ranking';
import { KakaoAuthPage } from './pages/Auth/kakao/authPage';
import { KakaoAuthgCallBackPage } from './pages/Auth/kakao/authCallback';
import { EnterpriseOnBoarding } from './pages/Enterprise/EnterpriseOnboarding';
import { ImmigotsNotifier } from './features/notifiers/Immigots/immigotsNotifier';
import { OnBoardEnterprise } from './pages/OnBoardEnterprise/OnBoard';
import { AdminLearnerData } from './pages/AdminLearnerData/AdminLearnerData';
import { ClassDetailPage } from './pages/ClassDetailPage/ClassDetailPage';
import { PlanChangePage } from './pages/PlanChangePage/PlanChangePage';
import { CertificatesPage } from './pages/Certificates/Certificates';
import { LearningPathPage } from './pages/LearningPath/LearningPath';
import { GuestPassCreateAccount } from './pages/GuestPassCreateAccount/GuestPassCreateAccount';
import { ProgressPage } from './pages/Progress/Progress';
import { ImprovementReportNotifier } from './features/notifiers/improvementReport/improvementReportNotifier';

const { auth } = initializeFirebase();

/**
 * Lazy loading both Report Card Pages because they include ChartJS and that's ~200Kb (Minified) as of today.
 * We don't need to add another 200Kb to our bundle if we're not going to need it.
 *
 * https://bundlephobia.com/package/react-chartjs-2@4.3.1
 * https://bundlephobia.com/package/chart.js@3.9.1
 */
const ReportCardForCoursePage = lazy(
  () => import('./features/ReportCard/pages/ReportCardForCoursePage'),
);

const Routing = () => {
  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  return (
    <Switch>
      <Suspense fallback={<div>Loading... </div>}>
        {userData ? <AchievemnetNotifier userID={userData?.id} /> : null}
        {userData && userData?.product?.metadata?.credits !== 'inf' ? (
          <ImmigotsNotifier userID={userData?.id} />
        ) : null}
        {userData ? <ImprovementReportNotifier uid={userData.id} /> : null}
        {/* Public routes */}
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/login" component={Login} />
        <Route path="/business" component={EnterpriseOnBoarding} />
        <Route path="/Certificates">
          <AppLayout pageTitle="Certificates">
            <Content>
              <CertificatesPage />
            </Content>
          </AppLayout>
        </Route>
        {/* End Public Routes */}
        <Route path="/home">
          <AppLayout pageTitle="">
            <Content>
              <Homepage />
            </Content>
          </AppLayout>
        </Route>
        {/* <Route path="/dashboard">
          <AppLayout pageTitle="Dashboard">
            <MainDashboard />
          </AppLayout>
        </Route> */}
        <Route path="/register">
          <AppLayout pageTitle="Course registration">
            <Content>
              <CourseRegistration />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/guest">
          <Content>
            <GuestPassCreateAccount />
          </Content>
        </Route>
        <Route path="/plans">
          <AppLayout pageTitle="Plans">
            <Content>
              <PlanChangePage />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/profile-setup">
          <AppLayout pageTitle="">
            <Content>
              <ProfileSetup />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/get-started">
          <AppLayout pageTitle="">
            <Content>
              <OnBoard />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/learning-path">
          <AppLayout pageTitle="">
            <Content>
              <LearningPathPage />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/progress">
          <AppLayout pageTitle="Progress">
            <Content>
              <ProgressPage />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/onboard-business">
          <AppLayout pageTitle="">
            <Content>
              <OnBoardEnterprise />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/additional-info">
          <AppLayout pageTitle="Additional Info">
            <Content>
              <AdditionalInfo />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/availability">
          <AppLayout pageTitle="Availability">
            <Content>
              <AvailabilityPage />
            </Content>
          </AppLayout>
        </Route>
        <Route exact path="/courselist">
          <AppLayout pageTitle="Your courses">
            <Content>
              <CourseList />
            </Content>
          </AppLayout>
        </Route>
        <Route exact path="/discount-codes">
          <AppLayout pageTitle="Discount codes">
            <Content>
              <DiscountCodes />
            </Content>
          </AppLayout>
        </Route>
        <Route exact path="/data/class-schedule">
          <AppLayout pageTitle="Booked Classes">
            <Content>
              <ClassSchedule />
            </Content>
          </AppLayout>
        </Route>
        <Route exact path="/data/class-edit">
          <AppLayout pageTitle="Class Edit">
            <Content>
              <ClassEdit />
            </Content>
          </AppLayout>
        </Route>
        <Route exact path="/data">
          <AppLayout pageTitle="Admin Data View">
            <Content>
              <AdminDataView />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/yearbook/C8nhULAzKczVlKryM4qL" component={YearBook} />
        {/* It's public, but it shouldn't be */}
        <Route path="/learners" component={AdminStudentDataView} />
        <Route exact path="/data/learners" component={AdminLearnerData} />
        <Route path="/report-cards" component={ReportCardView} />
        {/* Hybrid */}
        <Route path="/profile">
          <AppLayout pageTitle="Profile">
            <Content>
              <ProfilePage />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/pricing">
          <Content>
            <PricingPage />
          </Content>
        </Route>
        <Route path="/checkout">
          <Content>
            <PricingPageKorea />
          </Content>
        </Route>
        <Route path="/ai">
          <AppLayout pageTitle="Immigo AI">
            <Content>
              <FeedbackListener />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/insight">
          <AppLayout pageTitle="Your Class Insight">
            <Content>
              <ClassInsight />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/preview">
          <AppLayout pageTitle="">
            <Content>
              <ClassPreview />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/class-info">
          <AppLayout pageTitle="">
            <Content>
              <ClassDetailPage />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/subscription">
          <AppLayout pageTitle="Subscription">
            <Content>
              <Subscription />
            </Content>
          </AppLayout>
        </Route>
        {/* It's public, but it shouldn't be. It doesn't work if you're not logged in */}
        <Route path="/applications">
          <AppLayout pageTitle="Applications">
            <Content>
              <ApplicationView />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/schedule">
          <SchedulePage />
        </Route>
        <Route path="/class">
          <ClassLinkRedirect />
        </Route>
        <Route path="/wrapped">
          <YearlyInsight />
        </Route>
        <Route path="/classes">
          <AppLayout pageTitle="My Classes">
            <Content>
              <MyClasses />
            </Content>
          </AppLayout>
        </Route>
        <Route path="/ranking">
          <AppLayout pageTitle="Global Ranking">
            {userData?.id ? (
              <Content>
                <RankingPage uid={userData.id} />
              </Content>
            ) : null}
          </AppLayout>
        </Route>
        {/* <Route path="/meetings">
          <Meets />
        </Route> */}
        <Route path="/numbers">
          <NumbersPage />
        </Route>
        {/* <Route path="/chat" component={CommunityChat} /> */}
        {/* <Route exact path="/meeting-board">
          {userData?.id ? (
            <AppLayout pageTitle="Meeting board">
              <Content>
                <MeetingBoardPage />
              </Content>
            </AppLayout>
          ) : (
            <Redirect to="/login" />
          )}
        </Route> */}
        <Switch>
          {/* <Route path="/meeting-board/new">
            <AppLayout pageTitle="Create a new meeting">
              <Content>
                <CreateMeetingBoardPage />
              </Content>
            </AppLayout>
          </Route> */}
          {/* This route is hybrid, but it works in a non-react-router way */}
          {/* <Route exact path="/meeting-board/:meetingId">
            <AppLayout pageTitle="Meeting detail">
              <Content>
                <IndividualMeetingBoardPage />
              </Content>
            </AppLayout>
          </Route> */}
        </Switch>

        {/*
          This is a hack. Having a 404 is not something that we can do **right now** because of the way that some
          of the routes work, for example, `/applications` takes a dynamic param from the URL inside the component itself,
          and we don't declare that dynamic param using react-router.
          This means that if we decide to use

          <Route path="*">
            <NoMatch />
          </Router>

          It will 404 `/applications` because we don't declare that path in this file like
          <Route path="/application/:applicationId">...</Route>
        */}
        <Route path="/reports" exact>
          <Redirect to="/home" />
        </Route>

        <Route path="/reports/:userId" exact>
          {userData?.id ? (
            <AppLayout pageTitle="Course Reports">
              <Content>
                <ReportCardPageIndexPage user={userData} />
              </Content>
            </AppLayout>
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route path="/reports/:userId/:courseId" exact>
          {userData?.id ? (
            <AppLayout pageTitle="Course Reports">
              <Content>
                <ReportCardForCoursePage user={userData} />
              </Content>
            </AppLayout>
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        {/** Kakao login  */}
        <Route path="/auth/kakao" exact>
          <KakaoAuthPage />
        </Route>
        <Route path="/auth/callback/kakao" exact>
          <KakaoAuthgCallBackPage />
        </Route>
      </Suspense>
    </Switch>
  );
};

const AppComponent = () => {
  const [authUser, setAuthUser] = useState<any>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (inComingAuthUser) => {
      inComingAuthUser ? setAuthUser(inComingAuthUser) : setAuthUser(null);
    });
  }, []);

  return (
    <ReferralProvider>
      <Routing />
    </ReferralProvider>
  );
};

export const App = withAuthentication(AppComponent);
