import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Class ended'
  | 'Clicked class ended'
  | 'Stopped recording triggered for class voice recording'
  | 'Uploading class voice recording initiated'
  | 'Upload class voice recording succeeded'
  | 'Upload class voice recording encountered error'
  | 'Attempted to close class transcription page'
  | 'Upload class voice recording progress'
  | 'Showed survey page in the class redirect link'
  | 'Submitted who I talked to in survey page in the class redirect link'
  | 'Submitted their English level in survey page in the class redirect link'
  | 'Clicked back in survey page in the class redirect link'
  | 'Clicked next in survey page in the class redirect link'
  | `Clicked I didn't talk to anyone in survey page in the class redirect link`
  | `Chose rating in the survey page`
  | 'Chose reaction in the survey page'
  | 'Submitted improvement progress in the survey page'
  | 'Submitted reaction in the survey page'
  | 'Submitted class rating in the survey page';

type ClassLinkRedirectData = {
  sessionID?: string;
  bookerID?: string;
  bookingID?: string;
  fileSize?: number;
  error?: string;
  status?: string;
  rating?: string;
  ratee?: string;
  reaction?: string;
};

export const trackClassLinkRedirect = (
  event: Events,
  data?: ClassLinkRedirectData,
) => {
  mixpanel.track(event, data);
};
