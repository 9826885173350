import {
  AuthCredential,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  linkWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  User,
} from 'firebase/auth';
import { initializeFirebase } from '../configValues';
import momentTimezone from 'moment-timezone';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { UserData } from '../../types/user';
import { returnUserByUID, updateStudentTimeZone } from '../configuration';
import { analyticsUserCreatedAccount } from '../../analytics';
import { removeUndefinedProperties } from '../../util/data';
import { addEmailToWelcomeEmailSubList } from '../../api/email';
import { getFirstNameFromFullName } from '../../util/helper';

const { firestore: db, auth, storage } = initializeFirebase();

const errorTranslation: any = {
  'auth/wrong-password': `Incorrect email or password`,
  'auth/user-not-found': 'User with this email does not exist.',
  'auth/popup-closed-by-user':
    'You closed the sign in pop up. Please try again.',
  'auth/email-already-in-use':
    'An account with the given email already exists.',
};

const linkAuthAccounts = async (user: User, credential: AuthCredential) => {
  try {
    const userCredential = await linkWithCredential(user, credential);
  } catch (e: any) {
    switch (e.code) {
      case 'provider-already-linked':
        console.log('The provider has already been linked to the user.');
        break;
      case 'invalid-credential':
        console.log("The provider's credential is not valid.");
        break;
      case 'credential-already-in-use':
        console.log(
          'The account corresponding to the credential already exists, ""or is already linked to a Firebase User.',
        );
        break;
      // See the API reference for the full list of error codes.
      default:
        console.log('Unknown error.');
    }
  }
};

export const postAccountCreationAction = async (
  name: string,
  email: string,
  uid: string,
  userDataOverride?: Partial<UserData>,
) => {
  const timezoneOffset = new Date().getTimezoneOffset();

  let userDoc: Partial<UserData> = {
    name,
    authProvider: 'local',
    courses: [],
    email,
    timeZoneOffset: timezoneOffset,
    timeZone: momentTimezone.tz.guess(),
    createdAt: Date.now(),
  };

  if (userDataOverride) {
    const refinedOverride = removeUndefinedProperties(userDataOverride);
    userDoc = { ...userDoc, ...refinedOverride };
  }

  let spanish = false;
  const nationality = userDataOverride?.nationality;
  if (
    nationality === 'MX' ||
    nationality === 'GT' ||
    nationality === 'SV' ||
    nationality === 'HN' ||
    nationality === 'NI' ||
    nationality === 'CR' ||
    nationality === 'CU' ||
    nationality === 'DO' ||
    nationality === 'CO' ||
    nationality === 'VE' ||
    nationality === 'EC' ||
    nationality === 'PE' ||
    nationality === 'BO' ||
    nationality === 'CL' ||
    nationality === 'PY' ||
    nationality === 'UY' ||
    nationality === 'AR' ||
    nationality === 'BZ' ||
    nationality === 'GQ' ||
    nationality === 'PA'
  )
    spanish = true;

  const firstName = getFirstNameFromFullName(name);

  await setDoc(doc(db, 'users', uid), userDoc);
  await addEmailToWelcomeEmailSubList(email, firstName, spanish);

  return { ...userDoc, id: uid };
};

export const registerWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string,
  userDataOverride?: Partial<UserData>,
) => {
  let user;
  let error;

  try {
    const userData = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    if (userData?.user?.uid)
      user = await postAccountCreationAction(
        name,
        email,
        userData.user.uid,
        userDataOverride,
      );
  } catch (err: any) {
    console.log(err);
    error = errorTranslation[err.code] ?? err.code;
  }

  return { user, error };
};

export const registerWithFacebook = async (
  dataOverride?: Partial<UserData>,
) => {
  let error;
  let user;
  try {
    const facebookProvider = new FacebookAuthProvider();

    facebookProvider.setCustomParameters({ prompt: 'select_account' });

    const userData = await signInWithPopup(auth, facebookProvider);

    if (userData?.user) {
      const userAuthData = userData.user;

      const userDocFound = await returnUserByUID(userAuthData.uid);
      if (!userDocFound) {
        if (userAuthData.phoneNumber && dataOverride)
          dataOverride.phoneNumber = userAuthData.phoneNumber;
        if (userAuthData.photoURL && dataOverride)
          dataOverride.profilePictureLink = userAuthData.photoURL;
        if (userAuthData.uid && userAuthData.displayName && userAuthData.email)
          user = await postAccountCreationAction(
            userAuthData.displayName,
            userAuthData.email,
            userAuthData.uid,
            dataOverride,
          );
        if (userAuthData.email) analyticsUserCreatedAccount(userAuthData.email);
      } else {
        user = userDocFound;
      }
    }
  } catch (err: any) {
    console.log(err);
    error = errorTranslation[err.code] ?? err.code;
  }

  return { user, error };
};

export const registerWithGoogle = async (dataOverride?: Partial<UserData>) => {
  let error;
  let user;
  try {
    const googleProvider = new GoogleAuthProvider();
    googleProvider.setCustomParameters({ prompt: 'select_account' });

    const userData = await signInWithPopup(auth, googleProvider);

    if (userData?.user) {
      const userAuthData = userData.user;

      const userDocFound = await returnUserByUID(userAuthData.uid);
      if (!userDocFound) {
        if (userAuthData.phoneNumber && dataOverride)
          dataOverride.phoneNumber = userAuthData.phoneNumber;
        if (userAuthData.photoURL && dataOverride)
          dataOverride.profilePictureLink = userAuthData.photoURL;
        if (userAuthData.uid && userAuthData.displayName && userAuthData.email)
          user = await postAccountCreationAction(
            userAuthData.displayName,
            userAuthData.email,
            userAuthData.uid,
            dataOverride,
          );
        if (userAuthData.email) analyticsUserCreatedAccount(userAuthData.email);
      } else {
        user = userDocFound;
      }
    }
  } catch (err: any) {
    console.log(err);
    error = errorTranslation[err.code] ?? err.code;
  }

  return { user, error };
};

export const sendPasswordResetEmailHelper = async (email: string) => {
  let error;
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err: any) {
    console.log(err);
    error = errorTranslation[err.code] ?? err.code;
  }

  return error;
};

export const signInUserWithEmailAndPassword = async (
  email: string,
  password: string,
) => {
  let userData;
  let error;

  try {
    const methods = await fetchSignInMethodsForEmail(auth, email);
    if (!methods?.includes('password')) {
      const primaryMethod = methods?.[0];
      switch (primaryMethod) {
        case 'google.com':
          error =
            'Your account is linked with your Google account. Please continue with Google.';
          throw { code: error };
        case 'facebook.com':
          error =
            'Your account is linked with your Facebook account. Please continue with Facebook.';
          throw { code: error };
      }
    }
    userData = await signInWithEmailAndPassword(auth, email, password);

    // update on sign in
    if (userData?.user?.uid) {
      await updateStudentTimeZone(userData?.user?.uid);
    }
  } catch (err: any) {
    console.log(err);
    error = errorTranslation[err.code] ?? err.code;
  }

  return { userData, error };
};
